import pizzaSlice from '~/images/pizza-slice-solid.svg';
import shoppingBasket from '~/images/shopping-basket-solid.svg';
import plane from '~/images/plane-departure-solid.svg';
import creditCard from '~/images/credit-card-solid.svg';
import coffee from '~/images/coffee-solid.svg';
import hotel from '~/images/hotel-solid.svg';

import type { NumberInputItem, Redemption } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label:
      'Restaurants worldwide (4X points) on up to $50,000 in purchases per calendar year, then 1X',
    name: 'restaurants',
    icon: pizzaSlice,
    defaultValue: 6000,
  },
  {
    label:
      'U.S. supermarkets (4X points) on up to $25,000 in purchases per calendar year, then 1X',
    name: 'supermarkets',
    icon: shoppingBasket,
    defaultValue: 6000,
  },
  {
    label:
      'Flights booked directly with airlines or on amextravel.com (3X points)',
    name: 'flights',
    icon: plane,
    defaultValue: 2400,
  },
  {
    label:
      'Prepaid Hotels and other eligible travel through amextravel.com (2X points)',
    name: 'hotels',
    icon: hotel,
    defaultValue: 1000,
  },
  {
    label: 'All other purchases',
    name: 'other',
    icon: creditCard,
    defaultValue: 600,
  },
];

export const creditsItems: NumberInputItem[] = [
  {
    label:
      '$120 dining credit, up to $10 per month in statement credits at participating dining partners.',
    name: 'diningCredit',
    tooltip:
      'This can be used at Grubhub, The Cheesecake Factory, Goldbelly, Wine.com, and Five Guys. Enrollment required.',
    icon: pizzaSlice,
    defaultValue: 120,
    validation: {
      max: 120,
    },
  },
  {
    label: '$120 Uber Cash; automatically get up to $10 per month.',
    name: 'uberCredit',
    icon: pizzaSlice,
    defaultValue: 120,
    tooltip:
      'Basic Card Members on a Consumer U.S. American Express® Gold Card are eligible for $10 in Uber Cash each month ("Amex Benefit"), subject to these Terms and Conditions. To receive this benefit, you must have downloaded the latest version of the Uber App and your eligible American Express Gold Card must be a method of payment in your Uber account. The Amex Benefit may only be used in the United States.',
    validation: {
      max: 120,
    },
  },
  {
    label:
      '$100 Resy credit, up to $50 in statement credits semi-annually. Enrollment required.',
    name: 'resyCredit',
    icon: pizzaSlice,
    defaultValue: 100,
    tooltip:
      'After you pay with the American Express® Gold Card to dine at U.S. Resy restaurants or make other eligible Resy purchases.',
    validation: {
      max: 100,
    },
  },
  {
    label:
      "$84 Dunkin' Credit, up to $7 in monthly statement credits. Enrollment required.",
    name: 'dunkinCredit',
    icon: coffee,
    defaultValue: 84,
    tooltip:
      "After you enroll and pay with the American Express® Gold Card at Dunkin' locations.",
    validation: {
      max: 84,
    },
  },
];

export const redemptionItems: Redemption[] = [
  {
    label: 'Statement credit',
    name: 'statementCredit',
    value: 0.6,
  },
  {
    label: 'American Express® Business Checking',
    name: 'businessChecking',
    value: 0.8,
  },

  {
    label: 'Booking travel',
    name: 'bookingTravel',
    value: 1,
  },
  {
    label: 'Amex Business Checking w/ Business Platinum',
    name: 'businessCheckingWithBusinessPlatinum',
    value: 1,
  },
  {
    label: 'Cashing out via Schwab Platinum',
    name: 'cashingOutViaSchwabPlatinum',
    value: 1.1,
  },
  {
    label: 'Booking travel w/ Business Platinum rebate',
    name: 'bookingTravelWithBusinessPlatinumRebate',
    value: 1.25,
  },
  {
    label: 'Transferring to airline partners (target value)',
    name: 'transferringToAirlinePartners',
    value: 2,
  },
  {
    label: 'Custom',
    name: 'custom',
    value: null,
    acceptInput: true,
  },
];

export const data = {
  spendCategoryItems,
  creditsItems,
  redemptionItems,
  annualFee: 325,
};
